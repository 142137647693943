import React from "react"
import { ThemeProvider } from "styled-components"

import { BNSubApp } from "@gw/sub-app-bn"
import { lightTheme } from "@gw/theming"
import { EnvContext, getEnvVars } from "@gw/env-context"

function App() {
  return (
    <EnvContext.Provider value={getEnvVars()}>
      <ThemeProvider theme={lightTheme}>
        <BNSubApp></BNSubApp>
      </ThemeProvider>
    </EnvContext.Provider>
  )
}

export default App
